/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Paper,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  appContainer: {
    paddingLeft: '15rem',
    height: '90rem',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '10rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
    [theme.breakpoints.down('xs')]: {
      height: '85rem',
    },
  },
  featureImage: {
    height: '5rem',
    marginBottom: '4rem',
    cursor: 'pointer',
    '&.is-active': {
      marginBottom: '2rem',
    },
  },
  featureIndicator: {
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.common.greyDark,
    '&.is-active': {
      marginBottom: '-3px',
      height: '7px',
      backgroundColor: theme.palette.common.purple,
      borderRadius: '25px',
    },
  },
  featureDescription: {
    position: 'relative',
    marginTop: '5rem',
    padding: '3.9rem 6.9rem',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '1rem',
      marginRight: '1rem',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '-5rem',
      bottom: '-5rem',
      width: '20rem',
      height: '20rem',
      zIndex: -1,
      backgroundColor: theme.palette.common.purpleLight,
    },
  },
}));
export default function Features({ setSelectedTab, features }) {
  const classes = useStyles();
  const theme = useTheme();
  const matchDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
  const [selectedFeature, setSelectedFeature] = useState(0);
  const [hoverdFeature, setHoverdFeature] = useState(null);
  return (
    <Grid
      container
      className={classes.appContainer}
      direction="column"
      id="features"
      onMouseEnter={() => setSelectedTab(0)}
    >
      <Grid item container direction="column" style={{ marginTop: '6rem' }}>
        <Typography
          align={matchesSM ? 'center' : 'left'}
          variant="h2"
          style={{ fontWeight: 'bold' }}
          paragraph
        >
          Feature of Docquik
        </Typography>
        <Grid
          container
          style={{
            width: matchesXS ? '100%' : '62rem',
            marginTop: matchesXS ? '3rem' : '6rem',
            paddingLeft: '5%',
            paddingRight: '5%',
            alignSelf: 'left',
          }}
          direction="row"
        >
          {features.map((f, index) => (
            <Grid
              key={f.id}
              item
              container
              direction="column"
              alignItems="center"
              justify="flex-end"
              xs
              onClick={() => setSelectedFeature(index)}
              onMouseOver={() => setHoverdFeature(index)}
              onMouseOut={() => setHoverdFeature(null)}
            >
              <img
                src={f.icon[0]?.url}
                className={`${classes.featureImage} ${
                  index === selectedFeature ? 'is-active' : null
                }`}
              />
              {index === selectedFeature && (
                <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                  {f.title}
                </Typography>
              )}
              {index === hoverdFeature && index !== selectedFeature && (
                <Typography
                  variant="h6"
                  style={{ marginTop: '-2rem', marginBottom: '1rem' }}
                >
                  {f.title}
                </Typography>
              )}
              <div
                className={`${classes.featureIndicator} ${
                  index === selectedFeature ? 'is-active' : null
                }`}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid
        item
        container
        style={{
          height: '40rem',
          alignSelf: matchDownLg ? 'center' : undefined,
          width: matchDownLg ? '100%' : undefined,
        }}
        justify="space-evenly"
      >
        <Grid item sm={8}>
          <Paper
            style={{ maxWidth: '87rem', height: matchesXS ? '40rem' : '30rem' }}
            className={classes.featureDescription}
          >
            <Typography variant="h3" style={{ fontWeight: 'bold' }} paragraph>
              {features[selectedFeature]?.title}
            </Typography>
            <Typography variant="body2">
              {features[selectedFeature]?.description}
            </Typography>
          </Paper>
        </Grid>
        {matchesXS ? null : (
          <Grid item sm={4} style={{ paddingLeft: '2rem' }}>
            <img
              src={features[selectedFeature]?.image[0]?.url}
              alt=""
              style={{ maxWidth: '40rem', width: '100%' }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
Features.propTypes = {
  setSelectedTab: PropTypes.func.isRequired,
  features: PropTypes.array.isRequired,
};
