import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Theme from '../components/Theme';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import Feature from '../pages/Feature';
import Thankyou from '../components/Thankyou';
import ThankyouSignUp from '../components/ThankyouSignUp';

export default function HomePage() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [dialog, setDialog] = useState(false);

  return (
    <Router>
      <ThemeProvider theme={Theme}>
        <div className="homepage-container">
          <Header
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            dialog={dialog}
            setDialog={setDialog}
          />
          <Switch>
            <Route path="/" exact>
              <Feature dialog={dialog} setSelectedTab={setSelectedTab}/>
            </Route>
            <Route path="/thankyou" exact>
              <Thankyou/>
            </Route>
            <Route path="/thankyou_signup" exact>
              <ThankyouSignUp/>
            </Route>
          </Switch>
          <Footer dialog={dialog}/>
        </div>
      </ThemeProvider>
    </Router>
  );
}
