import { Slide } from '@material-ui/core';
import React from 'react';
import intro1 from '../assets/intro1.png';
import PropTypes from 'prop-types';
export default function TransitionIntro({ introIndex }) {
  const intros = [intro1, intro1];
  return (
    <>
      {[0, 1].map(
        (i) =>
          i === introIndex &&
            <Slide
              key={i}
              direction="right"
              in
              timeout={1000}
              exit={false}
              mountOnEnter
              appear
            >
              <img src={intros[i]} style={{ width: '100%', objectFit: 'cover' }}/>
            </Slide>
      )}
    </>
  );
}

TransitionIntro.propTypes = {
  introIndex: PropTypes.number.isRequired,
};
