import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import ClearIcon from '@material-ui/icons/Clear';
import PhoneInput from 'react-phone-input-2';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import CONFIG from '../constants/config';
import { notifyError, notifySuccess } from '../components/Alert';
import logo from '../assets/logo.svg';

const useStyle = makeStyles((theme) => ({
  container: {
    height: 'calc(100vh)',
    position: 'relative',
  },
  body: {
    backgroundColor: 'inherit',
    top: '13rem',
    left: '0',
    height: 'calc(100vh - 13rem)',
    [theme.breakpoints.down('lg')]: {
      top: '10%',
    },
  },
  body2: {
    backgroundColor: 'white',
    width: '100%',
    minWidth: '330px',
    boxShadow: '0px 0px 5px -2px grey',
  },
  dottButton: {
    width: '1rem',
    height: '1rem',
    borderRadius: '50%',
    marginLeft: '1rem',
    cursor: 'pointer',
    '&.is-active': {
      backgroundColor: theme.palette.common.purple,
    },
  },
  formContainer: {
    backgroundColor: '#F6F7FB',
    borderRadius: '5px',
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  textAreaContainer: {
    backgroundColor: 'white',
    borderRadius: 10,
    paddingTop: '1rem',
    paddingLeft: '2rem',
    boxShadow: '0 6px 12px -13px',
  },
  textArea: (props) => ({
    fontSize: props.message ? '2rem' : '1.6rem',
  }),
  sendMessage: {
    borderRadius: '25px',
    backgroundColor: theme.palette.common.purple,
    height: '4rem',
    width: '22rem',
    marginTop: '4rem',
    marginLeft: 'auto',
    textTransform: 'none',
    [theme.breakpoints.down('lg')]: {
      width: '20rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 'auto',
      marginBottom: '4rem',
    },
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));
export default function BookDemo({ setDialog }) {
  const classes = useStyle();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const [input, setInput] = useState({
    email: '',
    firstname: '',
    lastname: '',
    phonenumber: '',
    serviceRequest: '',
    message: '',
    captcha: '',
  });
  const [demoIndex, setDemoIndex] = useState(0);
  const [demos, setDemos] = useState([]);
  const [checkValidate, setCheckValidate] = useState(false);
  const handleChange = (e) => {
    setCheckValidate(false);
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangePhone = (e) => {
    setInput({
      ...input,
      phonenumber: e,
    });
  };
  const handleChangeCaptcha = (value) => {
    setInput({ ...input, captcha: value });
  };
  const handleClick = () => {
    setCheckValidate(true);
    axios({
      url: `${CONFIG.BASE_URL}/schedule-demo`,
      method: 'POST',
      data: {
        email: input.email,
        firstname: input.firstname,
        lastname: input.lastname,
        phonenumber: input.phonenumber,
        message: input.message,
        captcha: input.captcha,
      },
    })
      .then(({ data }) => {
        notifySuccess(data.msg);
        setInput({
          email: '',
          firstname: '',
          lastname: '',
          phonenumber: '',
          serviceRequest: '',
          message: '',
        });
        setCheckValidate(false);
        setDialog(false);
        history.push('/thankyou');
      })
      .catch((err) => {
        if (err.response) {
          notifyError(err.response.data.msg);
        } else {
          notifyError();
        }
      });
  };
  useEffect(() => {
    let interval1;
    axios({
      url: `${CONFIG.STRAPI_URL}/Demos`,
      method: 'GET',
    }).then(({ data }) => {
      setDemos(data);
      let count = 0;
      interval1 = setInterval(() => {
        setDemoIndex(count % data.length);
        count++;
      }, 5000);
    });
    return () => {
      clearInterval(interval1);
    };
  }, []);
  useEffect(() => {
    let subiz = '';
    const interval = setInterval(() => {
      subiz = document.querySelector('#subiz');
      if (subiz) {
        subiz.style.setProperty('display', 'none', 'important');
        clearInterval(interval);
      }
    }, 1);
    return () => {
      if (subiz) subiz.style.display = 'block';
    };
  });
  return (
    <Grid container className={classes.body2} id="bookdemo">
      <Grid
        item
        lg={5}
        md={6}
        direction="column"
        style={{
          padding: matchesXS ? '1.5rem 1rem' : '4rem 6rem',
          height: matchesXS ? 'fit-content' : undefined,
        }}
      >
        <img
          src={logo}
          className="header__logo"
          alt="logo"
          style={{
            marginTop: '-.5rem',
            marginBottom: '.5rem',
            cursor: 'pointer',
          }}
          onClick={() => {
            history.push('/');
          }}
        />
        <Typography
          variant="h2"
          style={{
            marginTop: '3rem',
            marginBottom: '3rem',
            fontWeight: 'bold',
          }}
          align={matchesXS ? 'center' : undefined}
        >
          Book your demo
        </Typography>
        {demos[demoIndex]?.image[0]?.url ? (
          <img
            src={demos[demoIndex].image[0].url}
            style={{
              width: '100%',
              justifyContent: 'center',
              minHeight: matchesSM ? undefined : '25rem',
              maxHeight: matchesSM ? undefined : '30rem',
            }}
          />
        ) : (
          <div
            style={{
              width: '100%',
              minHeight: '25rem',
              maxHeight: '30rem',
              backgroundColor: '#4B3DC9',
              opacity: 0.1,
            }}
          />
        )}
        <Grid
          container
          justify="center"
          style={{ marginTop: '1.5rem', marginBottom: '2rem' }}
        >
          {demos.map((i, index) => (
            <Paper
              key={i.id}
              elevation={4}
              className={`${classes.dottButton} ${
                demoIndex === index ? 'is-active' : undefined
              }`}
              onClick={() => setDemoIndex(index)}
            />
          ))}
        </Grid>
        <Typography
          variant="h3"
          align={matchesXS ? 'center' : undefined}
          style={{ marginBottom: '2rem', fontWeight: 'bold' }}
        >
          {demos[demoIndex]?.title}
        </Typography>
        <Typography
          variant="body2"
          align={matchesXS ? 'center' : undefined}
          style={{
            marginBottom: '1.6rem',
            fontSize: matchesXS ? '1.6rem' : '1.8rem',
          }}
        >
          {demos[demoIndex]?.description}
        </Typography>
      </Grid>
      <Grid
        item
        container
        alignItems={matchesXS ? undefined : 'center'}
        justify="center"
        lg={7}
        md={6}
        style={{
          backgroundColor: '#F6F7FB',
          height: matchesXS ? '100vh' : undefined,
          padding: matchesXS ? undefined : '2rem',
          paddingLeft: matchesXS ? undefined : '3rem',
          width: '100%',
        }}
      >
        <Grid className={classes.formContainer}>
          <Typography
            variant="h2"
            align={matchesXS ? 'center' : undefined}
            style={{
              marginTop: '6rem',
              marginBottom: '1.6rem',
              fontWeight: 'bold',
            }}
          >
            Schedule a demo
          </Typography>
          <Grid container direction="column">
            <div style={{ marginBottom: '2rem' }}>
              <TextField
                label="First Name"
                error={!input.firstname && checkValidate}
                style={{ width: 'calc(50% - 1rem)' }}
                value={input.firstname}
                name="firstname"
                onChange={handleChange}
              />
              <TextField
                label="Last Name"
                error={!input.lastname && checkValidate}
                style={{ width: 'calc(50% - 1rem)', marginLeft: '2rem' }}
                value={input.lastname}
                name="lastname"
                onChange={handleChange}
              />
            </div>
            <div style={{ marginBottom: '2rem' }}>
              <TextField
                label="Email"
                error={!input.email && checkValidate}
                style={{ width: '100%' }}
                value={input.email}
                name="email"
                onChange={handleChange}
              />
            </div>
            <div style={{ marginBottom: '2rem' }}>
              <PhoneInput
                country="in"
                inputClass={`phone-input ${
                  input.phonenumber ? 'is-active' : ''
                }`}
                inputProps={{
                  name: 'phone',
                  required: true,
                  autoFocus: true,
                }}
                value={input.phonenumber}
                countryCodeEditable={false}
                onChange={handleChangePhone}
              />
            </div>
            <p
              variant="h6"
              style={{
                fontSize: '1.6rem',
                color:
                  !input.message && checkValidate
                    ? 'red'
                    : theme.palette.common.black,
              }}
            >
              Message
            </p>
            <TextField
              placeholder="Add comment for a file"
              multiline
              rows={8}
              rowsMax={8}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.textArea,
                },
              }}
              className={classes.textAreaContainer}
              value={input.message}
              name="message"
              onChange={handleChange}
            />
            <ReCAPTCHA
              sitekey="6LeoCbgZAAAAAIGYlsqBj92wrqWamB-9C_RDMKKB"
              size="normal"
              hl="en"
              className="g-recaptcha"
              style={{ marginTop: '2rem' }}
              onChange={handleChangeCaptcha}
            />
            <Grid container>
              <Button
                variant="contained"
                className={classes.sendMessage}
                onClick={handleClick}
              >
                Send message
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ClearIcon
        fontSize="large"
        style={{
          position: 'fixed',
          top: '1rem',
          right: '1rem',
          cursor: 'pointer',
          width: '4rem',
          height: '4rem',
        }}
        onClick={() => setDialog(false)}
      />
    </Grid>
  );
}
BookDemo.propTypes = {
  setDialog: PropTypes.func.isRequired,
};
