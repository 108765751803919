import React from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import logo from '../assets/logo.svg';
import CONFIG from '../constants/config';
const useStyles = makeStyles((theme) => ({
  footer: {
    height: '13rem',
    backgroundColor: theme.palette.common.greyLight,
    paddingLeft: '15rem',
    paddingRight: '12rem',
    position: 'relative',
    zIndex: 9999,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '10rem',
      paddingRight: '9rem',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '5rem',
      paddingRight: '3rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1rem',
      paddingRight: '0',
    },
  },
  footerText: {
    color: theme.palette.common.greyDark,
    marginRight: '3rem',
    fontSize: '1.6rem',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    [theme.breakpoints.down('lg')]: {
      paddingRight: 'calc(80px - 9rem)',
    },
    [theme.breakpoints.down('md')]: {
      marginRight: 'calc(80px - 3rem)',
    },
  },
}));
export default function Footer({ dialog }) {
  const classes = useStyles();
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
  const redirectPolicy = () => {
    window.open(`${CONFIG.HOMEPAGE_URL}/policy`, '_blank');
  };
  return (
    <Grid
      container
      className={classes.footer}
      alignItems="center"
      style={{
        zIndex: dialog ? 1200 : 1301,
      }}
    >
      <img
        src={logo}
        className="header__logo"
        alt="logo"
        style={{
          maxHeight: matchesXS ? '50%' : '7rem',
          marginLeft: matchesXS ? undefined : '3rem',
        }}
      />
      <Grid item style={{ marginLeft: 'auto', display: 'flex' }}>
        <Typography
          variant="h4"
          className={classes.footerText}
          onClick={redirectPolicy}
        >
          Policies
        </Typography>
      </Grid>
    </Grid>
  );
}
Footer.propTypes = {
  dialog: PropTypes.bool.isRequired,
};
