import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function notifySuccess(msg) {
  if (msg) toast.success(msg);
  else toast.success('Success!');
}
export function notifyError(msg) {
  if (msg) toast.error(msg);
  else toast.error('Error!');
}
export function notifyInfo(msg) {
  if (msg) toast.info(msg);
  else toast.info('This feature is not available!');
}
