import React, { useState, useEffect } from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import logo from '../assets/logo.svg';
import {
  AppBar,
  Toolbar,
  Grid,
  Button,
  Dialog,
  Fade,
  useMediaQuery,
  SwipeableDrawer,
  List,
  ListItem,
  IconButton,
  ListItemText,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CONFIG from '../constants/config';
import PropTypes from 'prop-types';
import BookDemo from './BookDemo';
import MenuIcon from '@material-ui/icons/Menu';
function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 2 : 0,
  });
}
const useStyles = makeStyles((theme) => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: '5rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '3rem',
    },
  },
  appContainer: {
    paddingLeft: '15rem',
    paddingRight: '12rem',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '10rem',
      paddingRight: '9rem',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '5rem',
      paddingRight: '3rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '3rem',
      paddingRight: '0',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '1rem',
      paddingRight: '0',
    },
  },
  indicator: {
    backgroundColor: 'inherit',
  },
  mailTo: {
    textDecoration: 'none',
    marginLeft: '2rem',
    color: theme.palette.common.greyDark,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.common.purple,
    },
  },
  buttonContainer: {
    flex: 1,
    paddingLeft: '20%',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '15%',
    },
  },
  demoButton: {
    borderRadius: '25px',
    height: '4rem',
    borderColor: theme.palette.common.purple,
    width: '21.3rem',
    [theme.breakpoints.down('lg')]: {
      width: '19rem',
    },
  },
  signInButton: {
    borderRadius: '25px',
    backgroundColor: theme.palette.common.purple,
    height: '4rem',
    width: '21.6rem',
    marginLeft: '2.5rem',
    marginRight: '1rem',
    [theme.breakpoints.down('lg')]: {
      width: '20',
    },
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  tab: {
    width: '',
    [theme.breakpoints.down('lg')]: {
      width: '',
    },
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.common.black,
      fontWeight: 'bolder',
    },
  },
  drawer: {
    width: '70%',
  },
  drawerIcon: {
    height: '5rem',
    width: '5rem',
  },
  drawerItem: {
    ...theme.typography.h3,
  },
  bookDemoDialogPaper: {
    margin: 0,
    borderRadius: 0,
    backgroundColor: 'rgb(246, 247, 251)',
  },
  bookDemoDialogPaperScroll: {
    maxHeight: '100%',
  },
}));

export default function Header({ setSelectedTab, dialog, setDialog }) {
  const signUp = () => window.open(`${CONFIG.HOMEPAGE_URL}/pricing`, '_blank');
  const [isStartOfPage, setIsStartOfPage] = useState(true);
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const [openDrawer, setOpenDrawer] = useState(false);
  const classes = useStyles();
  const redirectToHomepage = () =>
    window.open(`${CONFIG.HOMEPAGE_URL}/`, '_blank');
  const handleBookDemo = () => setDialog(true);
  const routes = [
    {
      name: 'feature',
      link: '#features',
    },
    {
      name: 'Can help',
      link: '#canhelp',
    },
    {
      name: 'Testimonials',
      link: '#testimonials',
    },
  ];

  const tabs = (
    <>
      {/* <Tabs value={selectedTab} classes={{ indicator: classes.indicator }}>
        {routes.map((route, index) => (
          <Tab
            key={`${route.name}`}
            className={classes.tab}
            component={Link}
            to={route.link}
            label={route.name}
            onClick={() => {
              setSelectedTab(index);
              setTimeout(() => window.scrollBy(0, -90), 10);
            }}
          />
        ))}
      </Tabs> */}
      <Button
        variant="contained"
        className={classes.signInButton}
        onClick={signUp}
      >
        Sign Up
      </Button>
      <Button
        variant="outlined"
        className={classes.demoButton}
        onClick={handleBookDemo}
      >
        Schedule a demo
      </Button>
    </>
  );
  const drawer = (
    <React.Fragment>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{ paper: classes.drawer }}
      >
        <div className={classes.toolbarMargin}/>
        <List disablePadding style={{ marginTop: '5rem' }}>
          <ListItem
            onClick={() => {
              handleBookDemo();
              setOpenDrawer(false);
            }}
            divider
            button
            classes={{
              root: classes.drawerItemEstimate,
              selected: classes.drawerItemSelected,
            }}
          >
            <ListItemText className={classes.drawerItem} disableTypography>
              Schedule a demo
            </ListItemText>
          </ListItem>
        </List>
        <List disablePadding>
          <ListItem
            onClick={() => {
              signUp();
              setOpenDrawer(false);
            }}
            divider
            button
            classes={{
              root: classes.drawerItemEstimate,
              selected: classes.drawerItemSelected,
            }}
          >
            <ListItemText className={classes.drawerItem} disableTypography>
              Sign up
            </ListItemText>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <IconButton
        className={classes.drawerIconContainer}
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
      >
        <MenuIcon className={classes.drawerIcon}/>
      </IconButton>
    </React.Fragment>
  );
  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    routes.map((r, index) => {
      switch (window.location.pathname) {
        case r.link:
          setSelectedTab(index);
          break;
        default:
          break;
      }
    });
    function checkIsStartOfPage() {
      if (window.scrollY === 0) setIsStartOfPage(true);
      else setIsStartOfPage(false);
    }
    window.addEventListener('scroll', checkIsStartOfPage);
    return () => {
      window.removeEventListener('scroll', checkIsStartOfPage);
    };
  });
  return (
    <>
      <ElevationScroll>
        <AppBar
          elevation={0}
          className={classes.appContainer}
          style={{
            zIndex: dialog ? 1200 : 1301,
          }}
        >
          <Toolbar disableGutters className={classes.headerContainer}>
            <Grid
              container
              direction="column"
              spacing={2}
              style={{ marginTop: '.5rem' }}
            >
              <Grid item container>
                <Grid item>
                  <img
                    src={logo}
                    className="header__logo"
                    alt="logo"
                    style={{
                      marginTop: '-.5rem',
                      marginBottom: '.5rem',
                      cursor: 'pointer',
                    }}
                    onClick={redirectToHomepage}
                  />
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justify="flex-end"
                  className={classes.buttonContainer}
                >
                  {matchesSM ? drawer : tabs}
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div
        className={classes.toolbarMargin}
        style={{ marginBottom: isStartOfPage ? undefined : '4rem' }}
      />
      <Dialog
        open={dialog}
        TransitionComponent={Fade}
        keepMounted
        onClose={() => setDialog(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg"
        classes={{
          paper: classes.bookDemoDialogPaper,
          paperScrollPaper: classes.bookDemoDialogPaperScroll,
        }}
      >
        <BookDemo setDialog={setDialog}/>
      </Dialog>
    </>
  );
}
Header.propTypes = {
  // selectedTab: PropTypes.number.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
  dialog: PropTypes.bool.isRequired,
  setDialog: PropTypes.func.isRequired,
};
