import React, { useEffect } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import thankyou from '../assets/thankyou.png';

const useStyle = makeStyles((theme) => ({
  container: {
    height: '100vh',
    marginTop: '-10rem',
  },
  text: {
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
}));
export default function ThankyouSignUp() {
  const classes = useStyle();
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    // function gtag() {
    //   // eslint-disable-next-line prefer-rest-params
    //   window.dataLayer.push(arguments);
    // }
    // send_to: 'AW-499406963/56kTCLu0jeUBEPOwke4B',
    // gtag('event', 'conversion', {
    //   send_to: 'AW-499406963/YHRpCPn8i_cBEPOwke4B',
    //   value: 300.0,
    //   currency: 'INR',
    // });
  }, []);
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.container}
    >
      <img alt="" src={thankyou} style={{ maxWidth: '50rem', width: '80%' }}/>
      <Typography variant="body2" className={classes.text} align="center">
        Thank you for contacting us, we will get back to you as soon as
        possible!
      </Typography>
    </Grid>
  );
}
