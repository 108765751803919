import React, { useState } from 'react';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import axios from 'axios';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhoneInput from 'react-phone-input-2';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import CONFIG from '../constants/config';
import { notifySuccess, notifyError } from '../components/Alert.js';
import { useHistory } from 'react-router';
const useStyles = makeStyles((theme) => ({
  container: {
    width: 'calc(304px + 4rem)',
    position: 'fixed',
    bottom: 0,
    right: '3rem',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    [theme.breakpoints.down('xs')]: {
      right: 0,
      width: '100%',
    },
  },
  header: {
    borderRadius: '5px 5px 0 0',
    backgroundColor: '#EB3F21',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: '1rem 2rem 1rem 2rem',
    cursor: 'pointer',
    '@media (max-height: 700px)': {
      padding: '0.5rem 2rem 0.5rem 2rem',
    },
  },
  expandIcon: {
    fontSize: '3rem',
    marginRight: '1.5rem',
  },
  formGroup: {
    backgroundColor: 'white',
    padding: '1rem 2rem 1rem 2rem',
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem',
    '@media (max-height: 700px)': {
      marginBottom: '1rem',
      '& label': {
        fontSize: '1.4rem',
      },
    },
    '& input': {
      backgroundColor: '#F2F3F8',
      borderRadius: 5,
      border: 'none',
      height: '4rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    '& textarea': {
      resize: 'none',
      backgroundColor: '#F2F3F8',
      borderRadius: 5,
      border: 'none',
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  },
  sendButton: {
    alignSelf: 'center',
    border: 'none',
    backgroundColor: '#EB3F21',
    color: 'white',
    padding: '0.8rem 3rem 0.8rem 3rem',
    borderRadius: '3rem',
    marginTop: '2rem',
    marginBottom: '1rem',
    '@media (max-height: 700px)': {
      marginTop: '1rem',
    },
  },
}));
export default function LeaveMessage({ dialog }) {
  const classes = useStyles();
  const history = useHistory();
  const [isExpand, setIsExpand] = useState(true);
  const [input, setInput] = useState({
    name: '',
    email: '',
    phone: '',
    comment: '',
    captcha: '',
  });
  const matchShortScreen = useMediaQuery('@media (max-height : 700px)');
  const toggleExpand = () => setIsExpand(!isExpand);
  const handleChange = (event) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };
  const handleChangePhone = (e) => {
    setInput({
      ...input,
      phone: e,
    });
  };
  const handleSubmit = () => {
    axios({
      url: `${CONFIG.BASE_URL}/customer-message`,
      method: 'POST',
      withCredentials: true,
      data: {
        name: input.name,
        email: input.email,
        mobile: input.phone,
        comment: input.comment,
        captcha: input.captcha,
      },
    })
      .then(({ data }) => {
        notifySuccess(data.msg);
        history.push('/thankyou');
        setInput({
          name: '',
          email: '',
          phone: '',
          comment: '',
          captcha: '',
        });
        toggleExpand();
      })
      .catch((err) => {
        notifyError(err.response?.data?.msg);
      });
  };
  const reCaptCha = (value) => {
    setInput({ ...input, captcha: value });
  };
  if (isExpand) {
    return (
      <>
        <div className={classes.container} id="leavemessage" style={{ zIndex: dialog ? 1200 : 1302 }}>
          <div className={classes.header} onClick={toggleExpand}>
            <ExpandMoreIcon className={classes.expandIcon}/>
            <span style={{ fontSize: '2rem' }}>Leave us a Message</span>
          </div>
          <div className={classes.formGroup}>
            <div className={classes.formControl}>
              <label htmlFor="name">Your name</label>
              <input
                value={input.name}
                name="name"
                onChange={handleChange}
                placeholder="Enter your name"
              />
            </div>
            <div className={classes.formControl}>
              <label htmlFor="email">Email</label>
              <input
                value={input.email}
                name="email"
                onChange={handleChange}
                placeholder="Enter your email"
              />
            </div>
            <PhoneInput
              country="in"
              inputClass={`phone-input ${input.phonenumber ? 'is-active' : ''}`}
              inputProps={{
                name: 'phone',
                required: true,
                autoFocus: true,
              }}
              value={input.phone}
              countryCodeEditable={false}
              onChange={handleChangePhone}
            />
            <div className={classes.formControl}>
              <label htmlFor="comment">Your comment</label>
              <textarea
                value={input.comment}
                name="comment"
                onChange={handleChange}
                rows={matchShortScreen ? 3 : 5}
                placeholder="Enter your comment"
              />
            </div>
            <ReCAPTCHA
              sitekey="6LeoCbgZAAAAAIGYlsqBj92wrqWamB-9C_RDMKKB"
              onChange={reCaptCha}
              hl="en"
              name="captcha"
              className="g-recaptcha"
            />
            <button
              type="button"
              className={classes.sendButton}
              onClick={handleSubmit}
            >
              Send message
            </button>
          </div>
        </div>
      </>
    );
  }
  return (
    <div className={classes.container} style={{ zIndex: dialog ? 1200 : 1302 }}>
      <div className={classes.header} onClick={toggleExpand}>
        <ExpandLessIcon className={classes.expandIcon}/>
        <span style={{ fontSize: '2rem' }}>Leave us a Message</span>
      </div>
    </div>
  );
}
LeaveMessage.propTypes = {
  dialog: PropTypes.bool.isRequired,
};
