import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import bestChoice from '../assets/best.png';
import Axios from 'axios';
import CONFIG from '../constants/config';
const useStyles = makeStyles((theme) => ({
  pricingContainer: {
    width: '100%',
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {
      '&::before': {
        content: '""',
        clipPath: 'circle(28% at 60% 45%)',
        WebkitClipPath: 'circle(28% at 60% 45%)',
        backgroundColor: theme.palette.common.purpleLight,
        height: '60%',
        width: '40%',
        bottom: '0',
        left: '0',
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
          content: '',
        },
      },
      '&::after': {
        content: '""',
        clipPath: 'circle(33% at 50% 53%)',
        WebkitClipPath: 'circle(33% at 50% 53%)',
        backgroundColor: theme.palette.common.purpleLight,
        height: '60%',
        width: '40%',
        top: '0',
        right: '0',
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
          content: '',
        },
      },
    },
  },
  pricingCard: {
    width: '30%',
    height: 'fit-content',
    marginLeft: '.5rem',
    marginRight: '.5rem',
    position: 'relative',
    borderRadius: '10px',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.03, 1.1) ',
    },
    '&:hover $signInButton, &:hover $pricingDesc': {
      transform: 'scale(0.97, 0.9)',
    },
    '&.is-active': {
      transform: 'scale(1.03, 1.1)',
      border: `2px solid${theme.palette.primary.main}`,
    },
    '&.is-active $signInButton, &.is-active $pricingDesc': {
      transform: 'scale(0.97, 0.9)',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      marginBottom: '8rem',
    },
  },
  pricingDesc: {},
  pricingPrivilege: {
    '& ul': {
      paddingLeft: 0,
    },
    '& li': {
      listStyleType: 'none',
      fontSize: '1.6rem',
      color: 'rgb(112, 112, 112)',
      fontWeight: 300,
      marginBottom: '.8rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    '& strong': {
      color: 'rgb(83, 76, 221)',
      fontWeight: 500,
      fontSize: '1.8rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    marginBottom: '.8rem',
  },
  cardContainer: {
    width: '100%',
    paddingLeft: '15rem',
    paddingRight: '15rem',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '10rem',
      paddingRight: '10rem',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '5rem',
      paddingRight: '3rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '3rem',
      paddingRight: '0',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '1rem',
      paddingRight: '0',
    },
    alignSelf: 'center',
    position: 'relative',
    zIndex: 1,
  },
  cardContent: {
    paddingLeft: '3.5rem',
    paddingRight: '3rem',
    height: '100%',
  },
  signInButton: {
    borderRadius: '35px',
    backgroundColor: theme.palette.common.purple,
    height: '5rem',
    color: 'white',
    alignSelf: 'center',
    [theme.breakpoints.down('lg')]: {
      height: '4rem',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  switchTrack: {
    backgroundColor: theme.palette.primary.main,
  },
  switchThumb: {
    backgroundColor: theme.palette.common.white,
  },
}));
export default function PricingPlans() {
  const [pricings, setPricings] = useState([
    { id: 0, pricing_type: { Name: 'Month' } },
    { id: 1, pricing_type: { Name: 'Month' } },
    { id: 2, pricing_type: { Name: 'Month' } },
  ]);
  const classes = useStyles();
  const theme = useTheme();
  const matchesShortScreen = useMediaQuery('@media (max-height: 750px)');
  const [selectedPricing, setSelectedPricing] = useState(null);
  const [isYearly, setIsYearly] = useState(false);
  const handlePlanClick = () => {
    if (selectedPricing === 2) {
      window.open(
        `${CONFIG.HOMEPAGE_URL}/signup?plan_type=${
          isYearly ? 'Year' : 'Month'
        }&plan_name=PRO`
      );
    } else if (selectedPricing === 1) {
      window.open(
        `${CONFIG.HOMEPAGE_URL}/signup?plan_type=${
          isYearly ? 'Year' : 'Month'
        }&plan_name=STANDARD`
      );
    } else if (selectedPricing === 0) {
      window.open(`${CONFIG.HOMEPAGE_URL}/signup?plan_name=FREE`);
    }
  };
  const planOrder = {
    FREE: 0,
    STANDARD: 1,
    PRO: 2,
  };
  useEffect(() => {
    Axios({
      url: `${CONFIG.STRAPI_URL}/Pricings`,
      method: 'GET',
    }).then(({ data }) => {
      setPricings(data);
    });
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setIsYearly((x) => !x);
      console.log('x');
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <Grid
      container
      className={`${classes.pricingContainer}`}
      style={{ marginBottom: '6rem' }}
      direction="column"
    >
      <Grid
        item
        container
        direction="column"
        style={{ marginTop: '6rem', marginBottom: '6rem' }}
      >
        <Typography
          paragraph
          align="center"
          variant="h2"
          style={{
            fontWeight: 'bold',
            color: theme.palette.common.black,
            position: 'relative',
          }}
        >
          Pricing Plans
        </Typography>
        <div
          style={{
            display: 'flex',
            alignSelf: 'center',
            marginBottom: '2rem',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{
              color: theme.palette.common.black,
              fontWeight: !isYearly ? '500' : '300',
              marginRight: '2rem',
            }}
          >
            Monthly
          </Typography>
          <Switch
            classes={{
              track: classes.switchTrack,
              thumb: classes.switchThumb,
            }}
            checked={isYearly}
            color="primary"
            onClick={() => setIsYearly(!isYearly)}
          />
          <Typography
            style={{
              color: theme.palette.common.black,
              fontWeight: isYearly ? '500' : '300',
              marginLeft: '2rem',
            }}
          >
            Yearly
          </Typography>
        </div>
      </Grid>
      <Grid
        item
        container
        justify="space-around"
        className={classes.cardContainer}
      >
        {pricings
          .filter((p) => p.pricing_type?.Name === (isYearly ? 'Year' : 'Month'))
          .sort((a, b) => planOrder[a.plan] - planOrder[b.plan])
          .map((m, index) => (
            <Card
              elevation={4}
              className={`${classes.pricingCard} ${
                selectedPricing === index ? 'is-active' : undefined
              }`}
              key={m.id}
              onClick={() => setSelectedPricing(index)}
            >
              {m.best_choice === true && (
                <img
                  alt=""
                  src={bestChoice}
                  style={{
                    position: 'absolute',
                    top: '0',
                    right: '2rem',
                    maxWidth: '6rem',
                  }}
                />
              )}
              <CardContent
                className={classes.cardContent}
                style={{ minWidth: '30rem' }}
              >
                <Grid
                  container
                  direction="column"
                  justify="space-between"
                  style={{ height: '100%' }}
                >
                  <Grid className={classes.pricingDesc}>
                    <Typography
                      paragraph={!matchesShortScreen}
                      variant="h4"
                      style={{
                        marginTop: selectedPricing === index ? 0 : '1.6rem',
                        color:
                          selectedPricing === index
                            ? theme.palette.common.black
                            : theme.palette.common.greyDark,
                      }}
                    >
                      {m.plan}
                    </Typography>
                    <Grid container>
                      <Typography
                        style={{
                          fontWeight: 'bold',
                          color:
                            selectedPricing === index
                              ? theme.palette.primary.main
                              : theme.palette.common.black,
                          fontSize: '3rem',
                          alignSelf: 'flex-start',
                          marginTop: '1rem',
                          marginLeft: '1rem',
                        }}
                      >
                        ₹&nbsp;
                      </Typography>
                      <Typography
                        style={{
                          color:
                            selectedPricing === index
                              ? theme.palette.primary.main
                              : theme.palette.common.black,
                          fontWeight: 'bold',
                          fontSize: matchesShortScreen ? '5rem' : '7.2rem',
                        }}
                      >
                        {m.cost}{' '}
                        <span style={{ color: 'gray' }}>/ user/ month</span>
                      </Typography>
                    </Grid>
                    <Typography
                      style={{
                        color: 'black',
                        fontSize: '1.8rem',
                        marginTop: '-1rem',
                        marginBottom: '2rem',
                        height: m.summary ? undefined : '1.8rem',
                      }}
                    >
                      {m.summary}
                    </Typography>
                    <Grid className={classes.pricingPrivilege}>
                      <ReactMarkdown source={m.description}/>
                    </Grid>
                  </Grid>
                  {selectedPricing === index && (
                    <Button
                      variant="contained"
                      className={classes.signInButton}
                      onClick={handlePlanClick}
                    >
                      {/* {selectedPricing === 2 ? 'CONTACT US' : 'GET STARTED'} */}
                      GET STARTED
                    </Button>
                  )}
                </Grid>
              </CardContent>
            </Card>
          ))}
      </Grid>
    </Grid>
  );
}
