import React from 'react';
import { ToastContainer } from 'react-toastify';
import AppRouter from './router/AppRouter';
import 'react-phone-input-2/lib/material.css';
function App() {
  return (
    <React.Fragment>
      <ToastContainer/>
      <AppRouter/>
    </React.Fragment>
  );
}

export default App;
