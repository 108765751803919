/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  Card,
  Paper,
  useTheme,
  useMediaQuery,
  CardContent,
  Avatar,
} from '@material-ui/core';
import yellowStar from '../assets/yellowStar.svg';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Features from '../components/Features';
import TransitionIntro from '../components/TransitionIntro';
import CONFIG from '../constants/config';
import PropTypes from 'prop-types';
import LeaveMessage from '../components/LeaveMessage';
import axios from 'axios';
import PricingPlans from '../components/PricingPlans';
const useStyles = makeStyles((theme) => ({
  appContainer: {
    paddingLeft: '15rem',
    height: 'calc(100vh - 10rem)',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      maxHeight: '70rem',
      paddingLeft: '10rem',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '60rem',
      paddingLeft: 0,
    },
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#5845E5',
      maxHeight: 'fit-content',
      height: 'fit-content',
    },
  },
  signInButton: {
    borderRadius: '35px',
    backgroundColor: 'white',
    height: '6.5rem',
    width: '100%',
    marginTop: '3rem',
    marginBottom: '3rem',
    color: theme.palette.common.purple,
    [theme.breakpoints.down('lg')]: {
      height: '5rem',
    },
    '&:hover': {
      backgroundColor: '#F4F4F4',
    },
  },
  dottButton: {
    width: '1rem',
    height: '1rem',
    borderRadius: '50%',
    marginLeft: '1rem',
    cursor: 'pointer',
    '&.is-active': {
      backgroundColor: theme.palette.common.purple,
    },
  },
  featureImage: {
    height: '5rem',
    marginBottom: '4rem',
    cursor: 'pointer',
    '&.is-active': {
      marginBottom: '2rem',
    },
  },
  featureIndicator: {
    width: '100%',
    height: '1px',
    backgroundColor: theme.palette.common.greyDark,
    '&.is-active': {
      marginBottom: '-3px',
      height: '7px',
      backgroundColor: theme.palette.common.purple,
      borderRadius: '25px',
    },
  },
  featureDescription: {
    position: 'relative',
    marginTop: '5rem',
    padding: '3.9rem 6.9rem',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '-5rem',
      bottom: '-5rem',
      width: '20rem',
      height: '20rem',
      zIndex: -1,
      backgroundColor: theme.palette.common.purpleLight,
    },
  },
  heroText: {
    position: 'absolute',
    padding: '3rem',
    maxWidth: '35%',
    minHeight: '40rem',
    maxHeight: '50rem',
    backgroundColor: '#5845E5',
    zIndex: 99,
    top: 0,
    bottom: 0,
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: 'fit-content',
      maxHeight: 'unset',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '0',
      position: 'unset',
      maxWidth: 'unset',
      maxHeight: 'unset',
      height: 'unset',
    },
  },
  heroTitle: {
    fontWeight: 'bold',
    color: 'white',
  },
  canHelpContainer: {
    width: '100%',
    position: 'relative',
    backgroundColor: '#473DD1',
    paddingLeft: '15rem',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '10rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '3rem',
      paddingRight: '0',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '1rem',
      paddingRight: '0',
    },
  },
  dnaButton: {
    maxWidth: '30%',
    position: 'relative',
    cursor: 'pointer',
    zIndex: 99,
  },
  canHelpCard: {
    width: '33%',
    maxWidth: '37rem',
    maxHeight: '50rem',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  avatarButton: {
    position: 'absolute',
    top: '45%',
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    zIndex: 1,
    background: 'white',
    cursor: 'pointer',
    opacity: 0.5,
  },
  readMoreBtn: {
    borderRadius: '25px',
    height: '4.2rem',
  },
  testimonialContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  testimonialImg: {
    width: '45%',
    marginBottom: '.8rem',
  },
  canHelpLeft: {
    position: 'sticky',
    top: '200px',
    height: '80vh',
    paddingBottom: '150px',
    minHeight: '75rem',
    maxHeight: '80rem',
  },
  canHelpRight: {
    height: '100%',
    overflow: 'auto',
  },
  canHelpStatement: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  canHelpImgs: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  indicator: {
    position: 'absolute',
    backgroundColor: 'white',
    width: 4,
    height: '7.5rem',
    left: '-1.2rem',
    top: 0,
  },
}));
export default function Feature({ setSelectedTab, dialog }) {
  const [introIndex, setIntroIndex] = useState(0);
  const [canHelpIndex, setCanHelpIndex] = useState(0);
  const [testimonialIndex, setTestimonialIndex] = useState(0);
  const [utils, setUtils] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [features, setFeatures] = useState([]);
  const [intro, setIntro] = useState([]);
  const classes = useStyles();
  const rest = useRef(null);
  const theme = useTheme();
  const matchDownLg = useMediaQuery(theme.breakpoints.down('lg'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));
  const handleScroll = () => {
    const indicator = document.querySelector('#indicator');
    const canhelp = document.querySelector('#canhelp');
    const statements = document.querySelector('#statements');
    if (
      window.scrollY >= canhelp.offsetTop &&
      window.scrollY <=
        canhelp.offsetTop + canhelp.scrollHeight - window.innerHeight
    ) {
      const indicatorTop =
        ((statements.clientHeight - indicator.clientHeight) *
          (window.scrollY - canhelp.offsetTop)) /
        (canhelp.scrollHeight - window.innerHeight);
      indicator.style.top = `${indicatorTop}px`;
      setCanHelpIndex(
        Math.round(
          indicatorTop / document.getElementById('statement1').clientHeight
        )
      );
    }
  };
  const handleClickToScroll = (i) => {
    const c = document.querySelector('#canhelp');
    const s = document.querySelector(`#canHelpImg${i}`);
    const h = document.querySelector('header');
    const indicator = document.querySelector('#indicator');
    if (!i) {
      indicator.style.top = 0;
      setCanHelpIndex(0);
      window.scrollTo(0, c.offsetTop - h.clientHeight);
    } else {
      window.scrollTo(0, c.offsetTop + s.offsetTop - h.clientHeight);
    }
  };
  useEffect(() => {
    const utilsReq = axios({
      url: `${CONFIG.STRAPI_URL}/utils`,
      method: 'GET',
    });
    const testimonialsReq = axios({
      url: `${CONFIG.STRAPI_URL}/customer-reviews`,
      method: 'GET',
    });
    const featuresReq = axios({
      url: `${CONFIG.STRAPI_URL}/landing-features`,
      method: 'GET',
    });
    const introReq = axios({
      url: `${CONFIG.STRAPI_URL}/landing-introductions`,
      method: 'GET',
    });
    Promise.all([utilsReq, featuresReq, introReq, testimonialsReq]).then(
      ([
        { data: utilsRes },
        { data: featuresRes },
        { data: introRes },
        { data: testimonialsRes },
      ]) => {
        setUtils(utilsRes);
        setTestimonials(testimonialsRes);
        setFeatures(featuresRes);
        setIntro(introRes);
      }
    );
    if (window.innerWidth <= 595.95) window.onscroll = false;
    else window.onscroll = handleScroll;
    let count = 0;
    const interval = setInterval(() => {
      setIntroIndex(count % 2);
      count++;
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      {/* hero */}
      <Grid
        container
        className={classes.appContainer}
        style={{ position: 'relative' }}
        id="#"
        onMouseEnter={() => setSelectedTab(null)}
        direction={matchesXS ? 'column' : 'row'}
      >
        {matchesSM ? null : <Grid item md={3}/>}
        <Grid item md={9} sm={12} style={{ display: 'flex' }}>
          <TransitionIntro introIndex={introIndex}/>
        </Grid>
        <Grid item container direction="column" className={classes.heroText}>
          <Typography
            variant="h1"
            align="center"
            paragraph
            className={classes.heroTitle}
          >
            {intro[introIndex]?.title}
          </Typography>
          <Typography
            variant="body2"
            paragraph
            align="center"
            style={{ color: 'white' }}
          >
            {intro[introIndex]?.description}
          </Typography>
          <Grid item style={{ textAlign: 'center', marginTop: 'auto' }}>
            <Grid container justify="center">
              {intro.map((h, i) => (
                <Paper
                  key={h.id}
                  elevation={4}
                  className={`${classes.dottButton} ${
                    i === canHelpIndex ? 'is-active' : ''
                  }`}
                  style={{
                    backgroundColor: i === introIndex ? 'white' : '#8A7DEC',
                  }}
                  onClick={() => setIntroIndex(i)}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
        {introIndex !== 0 && (
          <Avatar
            className={classes.avatarButton}
            style={{
              left: matchesXS
                ? '1rem'
                : matchDownLg
                ? 'calc(10rem - 40px)'
                : 'calc(15rem - 40px)',
              top: matchesXS ? '13%' : '35%',
            }}
            onClick={() => setIntroIndex(introIndex <= 0 ? 0 : introIndex - 1)}
          >
            <ChevronLeftIcon fontSize="large" color="action"/>
          </Avatar>
        )}
        {introIndex !== 1 && (
          <Avatar
            className={classes.avatarButton}
            style={{
              right: matchesXS
                ? '1rem'
                : matchDownLg
                ? 'calc(10rem - 40px)'
                : 'calc(12rem - 40px)',
              top: matchesXS ? '13%' : '35%',
            }}
            onClick={() => setIntroIndex(introIndex >= 1 ? 1 : introIndex + 1)}
          >
            <ChevronRightIcon fontSize="large" color="action"/>
          </Avatar>
        )}
      </Grid>
      {/* pricing */}
      <PricingPlans/>
      {/* features */}
      <Features setSelectedTab={setSelectedTab} features={features}/>
      {/* docquik can help */}
      {!matchesXS && (
        <Grid
          container
          className={`${classes.canHelpContainer}`}
          direction="column"
          id="canhelp"
          onMouseEnter={() => setSelectedTab(1)}
        >
          <Typography
            paragraph
            align="left"
            variant="h2"
            style={{
              fontWeight: 'bold',
              color: '#F6E63E',
              position: 'relative',
              marginTop: '6rem',
              marginBottom: '2rem',
            }}
          >
            See how DocQuik can help
          </Typography>
          <div
            style={{ width: '30%', height: '1px', backgroundColor: '#F6E63E' }}
          />
          <Grid container style={{ marginTop: '2rem' }}>
            <Grid item md={5} sm={6} className={classes.canHelpLeft}>
              <Grid
                container
                className={classes.canHelpStatement}
                id="statements"
              >
                {utils.map((u, i) => (
                  <Grid
                    key={u.id}
                    id={`statement${i}`}
                    ref={i === 0 ? rest : null}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleClickToScroll(i)}
                  >
                    <Typography
                      variant="h4"
                      style={{
                        color: '#F6E63E',
                        fontWeight: 'bold',
                        opacity: i === canHelpIndex ? '1' : '.4',
                      }}
                    >
                      {u.title}
                    </Typography>
                    <Typography
                      paragraph={i !== utils.length - 1}
                      variant="body2"
                      style={{
                        color: 'white',
                        opacity: i === canHelpIndex ? '1' : '.4',
                      }}
                    >
                      {u.description}
                    </Typography>
                  </Grid>
                ))}
                <div className={classes.indicator} id="indicator"/>
              </Grid>
            </Grid>
            <Grid
              item
              md={7}
              sm={6}
              container
              direction="column"
              className={classes.canHelpRight}
            >
              {utils.map((u, i) => (
                <Grid
                  key={u.id}
                  item
                  className={classes.canHelpImgs}
                  id={`canHelpImg${i}`}
                >
                  <img
                    src={u?.image[0]?.url}
                    style={{
                      width: '85%',
                      alignSelf: i === 0 ? 'flex-start' : undefined,
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
      {matchesXS && (
        <Grid
          container
          className={`${classes.canHelpContainer}`}
          direction="column"
        >
          <Typography
            paragraph
            align="center"
            variant="h2"
            style={{
              fontWeight: 'bold',
              color: '#F6E63E',
              position: 'relative',
              marginTop: '6rem',
              marginBottom: '5rem',
            }}
          >
            See how DocQuik can help
          </Typography>
          {utils.map((u) => (
            <Grid
              key={u.id}
              item
              container
              direction="column"
              justify="center"
              alignItems="center"
              style={{ marginBottom: '4.5rem' }}
            >
              <Typography
                variant="h4"
                align="center"
                style={{
                  color: '#F6E63E',
                  fontWeight: 'bold',
                  marginBottom: '.8rem',
                }}
              >
                {u.title}
              </Typography>
              <Typography
                variant="body2"
                align="center"
                style={{
                  color: 'white',
                  width: '90%',
                }}
                paragraph
              >
                {u.description}
              </Typography>
              <img
                src={u?.image[0]?.url}
                style={{
                  width: '85%',
                }}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {/* testimonials */}
      <Grid
        id="testimonials"
        container
        style={{ height: matchesXS ? undefined : '90rem' }}
        direction="column"
        onMouseEnter={() => setSelectedTab(2)}
      >
        <Grid
          item
          container
          direction="column"
          style={{ marginTop: '8rem', marginBottom: '6rem' }}
        >
          <Typography
            paragraph
            align="center"
            variant="h2"
            style={{ fontWeight: 'bold' }}
          >
            What our customers have to say
          </Typography>
          {/* <Typography paragraph align="center" variant="body2">
            We are providing service for half a century operating on the
          </Typography> */}
        </Grid>
        <Grid
          item
          container
          direction={matchesXS ? 'column' : 'row'}
          justify="space-around"
          style={{
            width: matchesXS ? '90%' : '70%',
            alignSelf: 'center',
            position: 'relative',
            zIndex: 1,
          }}
        >
          {matchesXS ? null : (
            <>
              <Avatar
                className={classes.avatarButton}
                style={{ left: '0', top: '20%' }}
                onClick={() =>
                  setTestimonialIndex(
                    testimonialIndex <= 0 ? 0 : testimonialIndex - 1
                  )
                }
              >
                <ChevronLeftIcon
                  fontSize="large"
                  style={{ color: testimonialIndex === 0 ? 'grey' : 'black' }}
                />
              </Avatar>
              <Avatar
                className={classes.avatarButton}
                style={{ right: '0', top: '20%' }}
                onClick={() =>
                  setTestimonialIndex(
                    testimonialIndex + 1 <
                      Math.ceil(testimonials.length / 3) - 1
                      ? testimonialIndex + 1
                      : Math.ceil(testimonials.length / 3) - 1
                  )
                }
              >
                <ChevronRightIcon
                  fontSize="large"
                  style={{
                    color:
                      testimonialIndex ===
                      Math.ceil(testimonials.length / 3) - 1
                        ? 'grey'
                        : 'black',
                  }}
                />
              </Avatar>
            </>
          )}
          {!matchesXS &&
            testimonials
              .slice(testimonialIndex * 3, testimonialIndex * 3 + 3)
              .map((t) => (
                <Card className={classes.canHelpCard} key={t.id} elevation={0}>
                  <CardContent className={classes.testimonialContent}>
                    <img
                      src={t.avatar_url}
                      className={classes.testimonialImg}
                    />
                    <Typography
                      align="center"
                      variant="h4"
                      style={{ fontWeight: 'bold', marginBottom: '.8rem' }}
                    >
                      {t.user_name}
                    </Typography>
                    <Grid container justify="center">
                      {[1, 2, 3, 4, 5].map(
                        (i) =>
                          i <= t.star && (
                            <img
                              src={yellowStar}
                              style={{ width: '3rem', marginRight: '.5rem' }}
                            />
                          )
                      )}
                    </Grid>
                    <Typography
                      variant="body2"
                      align="center"
                      style={{ marginTop: '1rem' }}
                    >
                      {t.comment}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
          {matchesXS &&
            testimonials.map((t) => (
              <Card className={classes.canHelpCard} key={t.id} elevation={0}>
                <CardContent className={classes.testimonialContent}>
                  <img src={t.avatar_url} className={classes.testimonialImg}/>
                  <Typography
                    align="center"
                    variant="h4"
                    style={{ fontWeight: 'bold', marginBottom: '.8rem' }}
                  >
                    {t.user_name}
                  </Typography>
                  <Grid container justify="center">
                    {[1, 2, 3, 4, 5].map(
                      (i) =>
                        i <= t.star && (
                          <img
                            src={yellowStar}
                            style={{ width: '3rem', marginRight: '.5rem' }}
                          />
                        )
                    )}
                  </Grid>
                  <Typography
                    variant="body2"
                    align="center"
                    style={{ marginTop: '1rem' }}
                  >
                    {t.comment}
                  </Typography>
                </CardContent>
              </Card>
            ))}
        </Grid>
      </Grid>

      {/* Leave us a Message */}
      <LeaveMessage dialog={dialog}/>
    </>
  );
}
Feature.propTypes = {
  setSelectedTab: PropTypes.func.isRequired,
  dialog: PropTypes.bool.isRequired,
};
