import { createMuiTheme } from '@material-ui/core/styles';
/**
 * @property {string}  xs : 0px
 * @property {string}  sm : 600px
 * @property {string}  md : 960px
 * @property {string}  lg : 1280px
 * @property {string}  xl : 1920px
 */

const dqPurple = '#534CDD';
const dqPurpleLight = '#E4E1F9';
const dqPurpleDark = '#5845E5';
const dqGreyLight = '#F1F1F1';
const dqGrey = '#CCCCCC';
const dqGreyDark = '#707070';
const dqBlack = '#000000';
export default createMuiTheme({
  palette: {
    common: {
      purple: dqPurple,
      purpleLight: dqPurpleLight,
      purpleDark: dqPurpleDark,
      grey: dqGrey,
      greyLight: dqGreyLight,
      greyDark: dqGreyDark,
      black: dqBlack,
    },
    primary: {
      main: dqPurple,
    },
    secondary: {
      main: dqGrey,
    },
  },
  typography: {
    h1: {
      fontSize: '5.4rem',
      '@media (max-width: 1279.95px)': {
        fontSize: '4rem',
      },
      '@media (max-width: 959.95px)': {
        fontSize: '3.5rem',
      },
    },
    h2: {
      fontSize: '4.4rem',
      '@media (max-width: 1279.95px)': {
        fontSize: '3.8rem',
      },
      '@media (max-width: 959.95px)': {
        fontSize: '3rem',
      },
    },
    h3: {
      fontSize: '2.8rem',
      '@media (max-width: 959.95px)': {
        fontSize: '2rem',
      },
    },
    h4: {
      fontSize: '2.2rem',
      '@media (max-width: 959.95px)': {
        fontSize: '1.8rem',
      },
    },
    h5: {
      fontSize: '1.8rem',
    },
    h6: {
      fontSize: '1.4rem',
      color: dqGreyDark,
      fontWeight: 300,
    },
    body1: {
      fontSize: '2rem',
      color: dqPurple,
    },
    body2: {
      fontSize: '2rem',
      color: dqGreyDark,
      '@media (max-width: 959.95px)': {
        fontSize: '1.6rem',
      },
    },
    body3: {
      fontSize: '1.6rem',
      color: dqGreyDark,
      fontWeight: 300,
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: 'white',
        color: dqBlack,
      },
    },
    MuiTab: {
      root: {
        minWidth: 0,
        color: dqBlack,
        '@media (min-width: 600px)': {
          minWidth: 0,
        },
      },
      '&$selected': {
        fontWeight: 'bold',
      },
    },
    MuiSwitch: {
       track: {
         opacity: '1!important',
       },
    },
    MuiFormLabel: {
      root: {
        color: dqBlack,
        fontSize: '1.6rem',
      },
    },
  },
  custom: {
  },
});
