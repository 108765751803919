// const BASE_URL = 'http://localhost:3000/api';
const BASE_URL = 'https://aroc0rhzs8.execute-api.ap-south-1.amazonaws.com/prod/api';
// const APP_URL = 'http://localhost:3006';
const APP_URL = 'https://app.docquik.com';
const HOMEPAGE_URL = 'https://docquik.com';
// const HOMEPAGE_URL = 'http://localhost:3007';
const STRAPI_URL = ' https://cms.docquik.com';
const CONFIG = {
    BASE_URL,
    APP_URL,
    HOMEPAGE_URL,
    STRAPI_URL,
};
export default CONFIG;
